.inlineFlex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
img.thumbnail {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;
}
.rightArrow,
.leftArrow {
  z-index: 901;
  cursor: pointer;
  width: 7%;
  position: relative;
}
.arrow-icon {
  top: 38%;
  position: absolute;
  width: 100%;
  text-align: center;
}
.invisible {
  opacity: 0;
}
.product-cell {
  -webkit-break-inside: avoid-column;
  break-inside: avoid-column;
  display: inline-block;
  column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -webkit-column-break-inside: avoid;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 14px;
  background: transparent;
}
.product-cell div .itemLink {
  color: #555555;
}
.product-cell .form-control.mini {
  padding: 1px;
  padding-right: 0;
  height: 23px;
  line-height: 1;
  font-size: 11.5px;
}
.product-cell:hover .product-thumbnail-link {
  opacity: .75;
}
.item-picker .add-to-cart-container {
  width: 118px;
}
.item-picker .add-to-cart-container .product-narrative {
  padding-bottom: 0;
}
.item-picker .add-to-cart-container .product-narrative p {
  margin-bottom: 0;
}
.item-picker {
  clear: both;
  padding-top: 0;
  overflow: hidden;
  width: 87%;
  z-index: 900;
}
.item-picker.ie9 {
  width: auto;
}
.product-title {
  margin-top: 3px;
  text-align: left;
  float: left;
  font-size: 14px;
  color: #111111;
  padding: 3px 0 0 2px;
}
.product-scroller-container {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product-scroller-container .product-title {
  margin-top: 0;
  font-size: 12px;
}
.product-scroller-container .add-cart-to-container,
.product-scroller-container .product-thumbnail-link,
.product-scroller-container .product-title {
  width: 118px;
}
.product-list-table {
  margin: 0;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.widget-featured-products-widget h3,
.widget-recent-products-widget h3,
.widget-upsell-widget h3,
.widget-purchased-with-widget h3,
.widget-related-products-widget h3,
.widget-product-family-widget h3 {
  display: none;
}
.product-scroller {
  padding-bottom: 10px;
}
.product-list-widget-title h3 {
  font-size: 15px;
  clear: both;
  display: block;
  margin: 0;
  margin-left: 7%;
}
.rotating-deal-row {
  margin-left: -15px;
  margin-right: -15px;
}
.rotating-deal-row:before,
.rotating-deal-row:after {
  content: " ";
  display: table;
}
.rotating-deal-row:after {
  clear: both;
}
.rotating-deal {
  position: relative;
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.rotating-deal .product-cell {
  display: block;
  margin-left: 10px;
}
.rotating-deal .product-cell .product-thumbnail-link {
  width: 40%;
  float: left;
}
.rotating-deal .product-cell .product-thumbnail-link img {
  width: 90px;
  margin-left: 10px;
}
.rotating-deal .product-cell .add-to-cart-container {
  width: 50%;
  float: left;
  margin-left: 10px;
}
.rotating-deal .product-cell .product-title {
  font-size: 18px;
  margin: 0;
}
.rotating-deal .product-cell .form-control.mini {
  height: auto;
  padding: 3px;
  font-size: 12.5px;
}
.rotating-deal .product-cell .product-price.mini {
  font-size: 20px;
  margin-bottom: 5px;
}
.rotating-deal .product-cell .add-to-cart.mini-add-to-cart:after {
  font-size: 28px;
}
.rotating-deal .rotating-deal-announce {
  text-align: center;
}
.rotating-deal .rotating-deal-announce p {
  margin: 0;
}
.add-to-cart.mini-add-to-cart {
  color: #555555;
  background: transparent;
  padding: 1px;
  border: none;
  box-shadow: none;
  margin: 0;
  float: left;
}
.add-to-cart.mini-add-to-cart:after {
  font-family: 'FontAwesome';
  content: '\f07a';
  font-size: 1.333333em;
  margin: -3px 0 -5px 3px;
  float: left;
}
.product-price,
.cart-product-price {
  font-size: 20px;
  font-weight: bold;
}
.product-price.mini,
.cart-product-price.mini {
  font-size: 14px;
  float: left;
  padding-bottom: 0;
}
.productListPrice {
  font-weight: normal;
  font-size: 14px;
  padding: 2px;
  text-align: right;
}
.productListPrice.mini {
  font-size: 12px;
  padding: 0;
  float: left;
  padding-bottom: 2px;
}
.product-list-price-equal {
  font-weight: normal;
  font-size: 14px;
  padding: 2px;
  text-align: right;
}
.product-list-price-equal.mini {
  font-size: 12px;
  padding: 0;
  float: left;
  padding-bottom: 2px;
}
.product-list-price-strike-through {
  font-weight: normal;
  font-size: 14px;
  padding: 2px;
  text-align: right;
}
.product-list-price-strike-through.mini {
  font-size: 12px;
  padding: 0;
  float: left;
  padding-bottom: 2px;
}
@media (max-width: 1100px) {
  .rotating-deal .product-cell .product-thumbnail-link img {
    width: 70px;
  }
}
@media (max-width: 768px) {
  .rightArrow,
  .leftArrow {
    width: 10%;
    padding-top: 25%;
  }
}
@media (max-width: 400px) {
  .rightArrow,
  .leftArrow {
    width: 13%;
    padding-top: 50%;
  }
}
.product-browse-fields {
  margin-left: -15px;
  margin-right: -15px;
  padding-top: 10px;
}
.product-browse-fields:before,
.product-browse-fields:after {
  content: " ";
  display: table;
}
.product-browse-fields:after {
  clear: both;
}
.taxonomy-level,
.product-browse-button {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .taxonomy-level,
  .product-browse-button {
    float: left;
    width: 25%;
  }
}
